import { render, staticRenderFns } from "./BasketModal.vue?vue&type=template&id=a6c6dd88&scoped=true&"
import script from "./BasketModal.vue?vue&type=script&lang=js&"
export * from "./BasketModal.vue?vue&type=script&lang=js&"
import style0 from "./BasketModal.vue?vue&type=style&index=0&id=a6c6dd88&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6c6dd88",
  null
  
)

export default component.exports