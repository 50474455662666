<template>
  <div v-if="selected_search === 'stock'">
    <b-spinner v-if="disabled" class="mt-2" />
    <div v-if="!disabled" class="mt-2">
      <div v-if="items && items.length > 0" class="custom-table-container">
        <table class="custom-table">
          <thead>
            <tr>
              <th v-for="field in fields" :key="field.key">
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id" class="table-row">
              <td>{{ item.brand }}</td>
              <td style="width: 60px; padding: 0;">
                <div class="image-container">
                  <img
                    v-if="item.image"
                    :src="item.image"
                    :class="{ enlarged: selectedImage === item.image }"
                    @click="toggleImage(item, $event)"
                    style="width: 60px; height: 60px; object-fit: contain; display: block; cursor: pointer;"
                  />
                </div>
              </td>
              <td>
                {{
                  item.description && item.description.length > 0
                    ? item.description.substring(0, 30)
                    : null
                }}
              </td>
              <td>{{ item.kod }}</td>
              <!-- if bigger than 0 make it bold and if the value is 0 dont show -->
              <td>
                <span
                  :style="{
                    fontWeight: item.elit > 0 ? 'bold' : 'normal',
                    color: item.elit > 0 ? '#000' : '#aaa'
                  }"
                  >{{ item.elit }}</span
                >
              </td>
              <td>
                <span
                  :style="{
                    fontWeight: item.cobanzade > 0 ? 'bold' : 'normal',
                    color: item.cobanzade > 0 ? '#000' : '#aaa'
                  }"
                  >{{ item.cobanzade }}</span
                >
              </td>
              <td>
                <span
                  :style="{
                    fontWeight: item.sumqayit > 0 ? 'bold' : 'normal',
                    color: item.sumqayit > 0 ? '#000' : '#aaa'
                  }"
                  >{{ item.sumqayit }}</span
                >
              </td>
              <td>
                <span
                  :style="{
                    fontWeight: item.yolda > 0 ? 'bold' : 'normal',
                    color: item.yolda > 0 ? '#000' : '#aaa'
                  }"
                  >{{ item.yolda }}</span
                >
              </td>
              <td>
                <span :style="{ color: item.elit + item.cobanzade + item.sumqayit === 0 ? '#aaa' : '#000' }">
                  {{ item.elit + item.cobanzade + item.sumqayit === 0 ? 0 : item.price }}
                </span>
              </td>
              <td>
                <b-button
                  size="sm"
                  variant="light"
                  class="action-button"
                  @click="setProductId(item.id, 1)"
                >
                  <b-icon-cart4 scale="1.2" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StockTable",
  props: ["items", "fields", "disabled", "selected_search", "setProductId"],
  data() {
    return {
      selectedImage: null,
    };
  },
  methods: {
    toggleImage(item, event) {
      if (this.selectedImage === item.image) {
        this.selectedImage = null;
      } else {
        this.selectedImage = item.image;
        // Add window click listener when image is enlarged
        setTimeout(() => {
          window.addEventListener("click", this.handleOutsideClick);
        }, 0);
      }
      event.stopPropagation();
    },
    handleOutsideClick(event) {
      const enlargedImage = document.querySelector(".enlarged");
      if (enlargedImage && !enlargedImage.contains(event.target)) {
        this.selectedImage = null;
        window.removeEventListener("click", this.handleOutsideClick);
      }
    },
  },
  beforeDestroy() {
    // Clean up event listener when component is destroyed
    window.removeEventListener("click", this.handleOutsideClick);
  },
};
</script>
<style scoped>
.custom-table-container {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 1rem;
}

.custom-table th {
  background: #f8f9fa;
  padding: 0.75rem;
  font-weight: 600;
  color: #495057;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-table td {
  padding: 0.75rem;
  color: #212529;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.table-row {
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.table-row:last-child td {
  border-bottom: none;
}

.action-button {
  padding: 0.375rem 0.75rem;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: #f8f9fa;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}

.custom-table {
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
}

.custom-table thead th {
  border-bottom: 2px solid #dee2e6;
  background-color: #f8f9fa;
}

.image-container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.enlarged {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px !important;
  height: 600px !important;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain !important;
  z-index: 1000;
  cursor: zoom-out;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .custom-table-container {
    overflow-x: auto;
  }

  .custom-table {
    min-width: 800px;
  }
}
</style>
