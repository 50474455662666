<template>
  <div class="stock-page">
    <Navbar />
    <BasketModal @basket-updated="getBasketCounts" />
    <GlobalToast />

    <div class="page-content">
      <!-- Search Box Component -->
      <div class="contact-info-section">
        <form class="content-container" @submit.prevent="handleSubmit">
          <BasicSelect
            style="min-width: 180px;"
            v-model="selected_search"
            :options="search_types"
          />
          <InputText
            style="min-width: 200px;"
            placeholder="Kodu daxil edin..."
            v-model="kod"
            :required="true"
            ref="searchInput"
          />
          <b-button
            type="submit"
            variant="light"
            :disabled="disabled"
            class="search-button"
          >
            <b-icon-search variant="secondary" />
          </b-button>

          <b-button
            v-b-toggle.elit-basket
            variant="light"
            class="basket-btn"
            @click="setWarehouse(1)"
          >
            <b-icon-cart4 scale="1.25" variant="primary" />
            <span>Elit Səbət</span>
            <b-badge variant="success">{{ elitBasketCount }}</b-badge>
          </b-button>
          <b-button
            v-b-toggle.cobanzade-basket
            variant="light"
            class="basket-btn"
            @click="setWarehouse(2)"
          >
            <b-icon-cart4 scale="1.25" variant="primary" />
            <span>Çobanzadə Səbət</span>
            <b-badge variant="success">{{ cobanzadeBasketCount }}</b-badge>
          </b-button>
          <b-button
            v-b-toggle.sumqayit-basket
            variant="light"
            class="basket-btn"
            @click="setWarehouse(6)"
          >
            <b-icon-cart4 scale="1.25" variant="primary" />
            <span>Sumqayıt Səbət</span>
            <b-badge variant="info">{{ sumqayitBasketCount }}</b-badge>
          </b-button>

          <div class="text-danger" style="display: inline-flex; align-items: center; margin-left: 8px;">
            <span class="font-weight-bold">Borc: {{ parseFloat(borc).toFixed(2) }}</span>
          </div>
        </form>
      </div>

      <Messages :messages="messages" class="content-width" />
      <StockTable
        :items="stock_items"
        :fields="stock_fields"
        :disabled="disabled"
        :set-product-id="setProductId"
        :selected_search="selected_search"
        class="content-width"
      />
      <DubaiTable
        :items="dubai_items"
        :fields="dubai_fields"
        :disabled="disabled"
        :selected_search="selected_search"
        class="content-width"
      />
      <ElitSidebar ref="elitSidebar" />
      <CobanzadeSidebar ref="cobanzadeSidebar" />
      <SumqayitSidebar ref="sumqayitSidebar" />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import axios from "axios";
import BasicSelect from "@/components/BasicSelect";
import InputText from "@/components/InputText";
import BasketModal from "@/components/BasketModal";
import Messages from "@/components/Messages";
import GlobalToast from "@/components/GlobalToast";
import StockTable from "@/components/StockTable";
import DubaiTable from "@/components/DubaiTable";
import ElitSidebar from "@/components/ElitSidebar";
import CobanzadeSidebar from "@/components/CobanzadeSidebar";
import SumqayitSidebar from "@/components/SumqayitSidebar";
import { mapState } from "vuex";

export default {
  name: "Stock",
  components: {
    CobanzadeSidebar,
    ElitSidebar,
    SumqayitSidebar,
    DubaiTable,
    StockTable,
    GlobalToast,
    Messages,
    BasketModal,
    InputText,
    BasicSelect,
    Navbar,
  },
  data() {
    return {
      disabled: false,
      borc: 0,

      kod: null,
      current_warehouse: 1, // Set default warehouse to Elit
      stock_items: [],
      dubai_items: [],
      stock_fields: [
        { key: "kod", label: "Kod" },
        { key: "image", label: "Şəkil" },
        { key: "name", label: "Ad" },
        { key: "brand", label: "Brend" },
        { key: "elit", label: "Elit" },
        { key: "cobanzade", label: "Çobanzadə" },
        { key: "sumqayit", label: "Sumqayıt" },
        { key: "yolda", label: "Yolda" },
        { key: "price", label: "Qiymət" },
        { key: "actions", label: "" },
      ],
      dubai_fields: [
        {key: "brand", label: "Brend"},
        {key: "description", label: "Malın Adı"},
        {key: "kod", label: "Kod"},
        {key: "price", label: "Qiymət (AZN) "},
      ],
      search_types: [
        { text: "Stokda axtar", value: "stock" },
        { text: "Dubayda axtar", value: "dubai" },
      ],
      selected_search: "stock",
      messages: [],
    };
  },
  computed: {
    ...mapState({
      basketCount1: (state) => state.basketCount1,
      basketCount2: (state) => state.basketCount2,
      basketCount6: (state) => state.basketCount6,
    }),
    elitBasketCount() {
      return this.basketCount1;
    },
    cobanzadeBasketCount() {
      return this.basketCount2;
    },
    sumqayitBasketCount() {
      return this.basketCount6;
    },
  },
  created() {
    this.$store.commit("SET_SECTION", "MƏHSULLAR");
    this.fetchMessages();
    this.refreshBaskets();
    this.fetchBorc();
  },
  mounted() {
    // Focus the input after component is mounted
    this.$nextTick(() => {
      if (this.$refs.searchInput) {
        const inputElement = this.$refs.searchInput.$el.querySelector("input");
        if (inputElement) {
          inputElement.focus();
        }
      }
    });
  },
  methods: {
    async fetchMessages() {
      try {
        const response = await axios.post("/stock/messages");
        this.messages = response.data.messages || [];
        console.log("Fetched messages:", this.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    },
    fetchBorc() {
      axios.post("/sales_invoices_credit_advance").then((result) => {
        this.borc = result.data.borc;
      });
    },
    handleSubmit() {
      if (this.selected_search === "stock") {
        this.handleStockSearch();
      }
      if (this.selected_search === "dubai") {
        this.handleDubaySearch();
      }
    },
    handleStockSearch() {
      const kod = this.kod
        ? this.kod
            .split("-")
            .join("")
            .trim()
        : "";
      this.disabled = true;

      axios.post("/stock", { kod }).then((result) => {
        this.disabled = false;
        if (result.data.success) {
          this.stock_items = result.data.results;
        } else {
          this.stock_items = [];
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
        }
      });
    },
    handleDubaySearch() {
      const kod = this.kod
        ? this.kod
            .split("-")
            .join("")
            .trim()
        : "";
      this.disabled = true;
      axios.post("/dubai", { kod }).then((result) => {
        this.disabled = false;
        if (result.data.success) {
          this.dubai_items = result.data.results;
        } else {
          this.dubai_items = [];
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
        }
      });
    },
    setProductId(product_id, quantity = 0) {
      console.log("Setting product:", {
        product_id,
        quantity,
        warehouse_id: this.current_warehouse,
      });
      this.$root.$emit("bv::show::modal", "basket_modal", {
        product_id: parseInt(product_id),
        quantity: parseInt(quantity) || 0,
        warehouse_id: this.current_warehouse,
      });
    },
    setWarehouse(id) {
      this.current_warehouse = id;
    },
    getBasketCount(warehouseId) {
      return warehouseId === 1 ? this.basketCount1 : this.basketCount2;
    },
    refreshBaskets() {
      console.log("Refreshing baskets...");
      if (this.$refs.elitSidebar) {
        this.$refs.elitSidebar.fetchItems();
      }
      if (this.$refs.cobanzadeSidebar) {
        this.$refs.cobanzadeSidebar.fetchItems();
      }
    },
    getBasketCounts() {
      this.refreshBaskets();
    },
  },
};
</script>

<style scoped>
.stock-page {
  width: 100%;
}

.page-content {
  max-width: 1228px;
  margin: 0 auto;
  padding: 0 1rem;
}

.content-width {
  width: 100%;
  margin-top: 1rem;
}

.content-width :deep(.card),
.content-width :deep(.table-responsive),
.content-width :deep(.alert),
.content-width :deep(.border),
.content-width :deep(.b-sidebar) {
  border-radius: 12px !important;
  overflow: hidden;
}

.content-width :deep(table) {
  border-radius: 12px;
  overflow: hidden;
}

.content-width :deep(table tr:first-child th:first-child) {
  border-top-left-radius: 12px;
}
.content-width :deep(table tr:first-child th:last-child) {
  border-top-right-radius: 12px;
}
.content-width :deep(table tr:last-child td:first-child) {
  border-bottom-left-radius: 12px;
}
.content-width :deep(table tr:last-child td:last-child) {
  border-bottom-right-radius: 12px;
}

.content-width :deep(.table-container) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  overflow: hidden;
}

.content-width :deep(.table) {
  margin-bottom: 0;
  background: white;
  border-radius: 12px;
}

.content-width :deep(.table.b-table) {
  border-radius: 12px !important;
}

.content-width :deep(.table-responsive) {
  border-radius: 12px;
  overflow: hidden;
}

.content-width :deep(.table.b-table > :not(:last-child) > :last-child > *) {
  border-bottom-color: #dee2e6;
}

.content-width :deep(.table.b-table.table-bordered) {
  border: none;
}

.contact-info-section {
  width: 100%;
  padding: 0.75rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.75rem;
}

.content-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  margin: 0;
}

form.content-container {
  margin: 0;
  padding: 0;
}

/* Common styles for all interactive elements */
.search-select,
.search-input,
.search-button,
.basket-btn,
.debt-branch,
.branch {
  height: 42px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background: #fff;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.search-select {
  width: 180px;
  flex: 0 0 auto;
}

.search-input {
  width: 380px;
  flex: 0 0 auto;
}

.search-button {
  width: 42px;
  padding: 0;
  justify-content: center;
  flex: 0 0 auto;
}

.basket-btn {
  padding: 0 1rem;
  gap: 0.5rem;
  font-weight: 500;
  white-space: nowrap;
  flex: 0 0 auto;
}

.branches-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid #dee2e6;
  flex: 0 0 auto;
}

.branch {
  padding: 0 0.75rem;
  gap: 0.5rem;
  white-space: nowrap;
  flex: 0 0 auto;
}

.debt-branch {
  padding: 0 0.75rem;
  background: #f8f9fa;
}

.branch-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
}

.branch-name {
  font-weight: 600;
  font-size: 0.75rem;
  color: #212529;
  line-height: 1;
}

.branch-contact {
  font-size: 0.75rem;
  color: #495057;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  transition: color 0.2s ease;
  line-height: 1;
}

.branch-contact:hover {
  color: #25d366;
}

.whatsapp-icon {
  color: #25d366;
  font-size: 1.125rem;
}

.branch-divider {
  width: 1px;
  height: 20px;
  background: #dee2e6;
  margin: 0;
}

.debt-amount {
  font-size: 0.75rem;
  color: #dc3545;
  font-weight: 600;
  line-height: 1;
}

/* Table styles */
:deep(.table) {
  border-collapse: collapse;
  margin-bottom: 0;
  font-size: 1rem;
}

:deep(.table th),
:deep(.table td) {
  border: 1px solid #dee2e6;
  padding: 0.75rem;
  vertical-align: middle;
}

:deep(.table td:nth-child(2)) {
  padding: 0;
  width: 60px;
  height: 60px;
}

:deep(.table thead th) {
  border-bottom: 2px solid #dee2e6;
  background-color: #f8f9fa;
  font-weight: 600;
  font-size: 1.0625rem;
}

@media (max-width: 768px) {
  .page-content {
    padding-top: 60px;
    position: relative;
    z-index: 1;
  }

  .contact-info-section {
    margin-bottom: 1rem;
  }

  .content-container {
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
  }

  .branches-group {
    display: none;
  }

  .basket-btn {
    width: 100%;
  }
}
</style>
