var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selected_search === 'stock')?_c('div',[(_vm.disabled)?_c('b-spinner',{staticClass:"mt-2"}):_vm._e(),(!_vm.disabled)?_c('div',{staticClass:"mt-2"},[(_vm.items && _vm.items.length > 0)?_c('div',{staticClass:"custom-table-container"},[_c('table',{staticClass:"custom-table"},[_c('thead',[_c('tr',_vm._l((_vm.fields),function(field){return _c('th',{key:field.key},[_vm._v(" "+_vm._s(field.label)+" ")])}),0)]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id,staticClass:"table-row"},[_c('td',[_vm._v(_vm._s(item.brand))]),_c('td',{staticStyle:{"width":"60px","padding":"0"}},[_c('div',{staticClass:"image-container"},[(item.image)?_c('img',{class:{ enlarged: _vm.selectedImage === item.image },staticStyle:{"width":"60px","height":"60px","object-fit":"contain","display":"block","cursor":"pointer"},attrs:{"src":item.image},on:{"click":function($event){return _vm.toggleImage(item, $event)}}}):_vm._e()])]),_c('td',[_vm._v(" "+_vm._s(item.description && item.description.length > 0 ? item.description.substring(0, 30) : null)+" ")]),_c('td',[_vm._v(_vm._s(item.kod))]),_c('td',[_c('span',{style:({
                  fontWeight: item.elit > 0 ? 'bold' : 'normal',
                  color: item.elit > 0 ? '#000' : '#aaa'
                })},[_vm._v(_vm._s(item.elit))])]),_c('td',[_c('span',{style:({
                  fontWeight: item.cobanzade > 0 ? 'bold' : 'normal',
                  color: item.cobanzade > 0 ? '#000' : '#aaa'
                })},[_vm._v(_vm._s(item.cobanzade))])]),_c('td',[_c('span',{style:({
                  fontWeight: item.sumqayit > 0 ? 'bold' : 'normal',
                  color: item.sumqayit > 0 ? '#000' : '#aaa'
                })},[_vm._v(_vm._s(item.sumqayit))])]),_c('td',[_c('span',{style:({
                  fontWeight: item.yolda > 0 ? 'bold' : 'normal',
                  color: item.yolda > 0 ? '#000' : '#aaa'
                })},[_vm._v(_vm._s(item.yolda))])]),_c('td',[_c('span',{style:({ color: item.elit + item.cobanzade + item.sumqayit === 0 ? '#aaa' : '#000' })},[_vm._v(" "+_vm._s(item.elit + item.cobanzade + item.sumqayit === 0 ? 0 : item.price)+" ")])]),_c('td',[_c('b-button',{staticClass:"action-button",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){return _vm.setProductId(item.id, 1)}}},[_c('b-icon-cart4',{attrs:{"scale":"1.2"}})],1)],1)])}),0)])]):_vm._e()]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }