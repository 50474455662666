<!-- CobanzadeSidebar.vue -->
<template>
  <div>
    <b-sidebar
      id="cobanzade-basket"
      no-header
      @shown="fetchItems"
      backdrop
      left
      :width="isMobile ? '100%' : '1200px'"
    >
      <div class="m-3">
        <div v-if="isMobile" class="mobile-close-btn border px-3 rounded font-w" v-b-toggle.cobanzade-basket>
          X
        </div>

        <!-- Desktop Layout -->
        <template v-if="!isMobile">
          <RowCol class="justify-content-between align-items-center">
            <div class="d-flex flex-grow-1 align-items-center">
              <SectionHeader name="Çobanzadə Səbət" />
              <b-icon-cart4 class="ml-3 mb-3" scale="2" variant="primary" />
              <div class="d-flex align-items-center text-success ml-auto px-2 border shadow-sm rounded">
                <i span class="fab fa-whatsapp mr-2 mt-2 h4" />
                <span class="font-weight-bold">050 700 27 56</span>
              </div>
            </div>
          </RowCol>
          <RowCol class="mb-3">
            <b-form @submit.prevent="handleSubmitBasket" inline>
              <InputText
                class="w-50"
                v-model="note"
                :required="false"
                name="Qeyd:"
              />
              <b-button
                type="submit"
                variant="light"
                class="shadow-sm ml-2 border font-weight-bold text-success"
              >
                Sifarişi Göndər
              </b-button>
              <b-button
                v-if="items && items.length > 0"
                variant="light"
                @click="handleFullDelete"
                class="shadow-sm ml-2 border font-weight-bold text-danger"
              >
                Səbəti Təmizlə
              </b-button>
            </b-form>
          </RowCol>
        </template>

        <!-- Mobile Layout -->
        <template v-else>
          <div class="mobile-list">
            <div class="list-item">
              <div class="d-flex align-items-center">
                <SectionHeader name="Çobanzadə Səbət" />
                <b-icon-cart4 class="ml-3" scale="2" variant="primary" />
              </div>
            </div>

            <div class="list-item">
              <div class="d-flex align-items-center justify-content-center text-success border shadow-sm rounded p-2 w-100">
                <i class="fab fa-whatsapp mr-2 h4 mb-0" />
                <span class="font-weight-bold">050 700 27 56</span>
              </div>
            </div>

            <div class="list-item">
              <b-form @submit.prevent="handleSubmitBasket" class="w-100">
                <InputText
                  class="w-100 mb-2"
                  v-model="note"
                  :required="false"
                  name="Qeyd:"
                />
                <div class="d-flex flex-column gap-2">
                  <b-button
                    type="submit"
                    variant="light"
                    class="shadow-sm border font-weight-bold text-success w-100"
                  >
                    Sifarişi Göndər
                  </b-button>
                  <b-button
                    v-if="items && items.length > 0"
                    variant="light"
                    @click="handleFullDelete"
                    class="shadow-sm border font-weight-bold text-danger w-100"
                  >
                    Səbəti Təmizlə
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </template>
        <RowCol>
          <b-table
            small
            bordered
            outlined
            hover
            :items="items"
            :fields="fields"
            :busy="disabled"
            class="shadow"
          >
            <template #table-busy>
              <b-spinner variant="dark" class="my-3 align-middle" />
            </template>

            <template #bottom-row>
              <td colspan="4"></td>
              <td class="font-weight-bolder">CƏM:</td>
              <td class="font-weight-bolder">
                {{ total_quantity ? total_quantity : 0 }}
              </td>
              <td></td>
              <td class="font-weight-bolder">
                {{ total ? parseFloat(total).toFixed(2) : 0 }}
              </td>
            </template>

            <template #cell(selected)="data">
              <b-form-checkbox
                class="ml-2"
                :checked="data.item.selected"
                @change="handleSelectedChange(data.item.id, $event)"
              />
            </template>

            <template #head(selected)>
              <b-form-checkbox
                class="ml-2"
                :checked="fullchecked"
                @change="handleFullCheck"
              />
            </template>

            <template #cell(N)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(description)="data">
              {{
                data.item.description && data.item.description.length > 0
                  ? data.item.description.substring(0, 30)
                  : null
              }}
            </template>

            <template #cell(mebleg)="data">
              {{ parseFloat(data.item.quantity * data.item.price).toFixed(2) }}
            </template>

            <template #cell(price)="data">
              {{ parseFloat(data.item.price).toFixed(2) }}
            </template>

            <template #cell(quantity)="data">
              <strong>{{ data.item.quantity }}</strong>
            </template>

            <template #cell(-)="data">
              <b-button
                size="sm"
                variant="light"
                class="ml-1 shadow-sm border"
                @click="
                  setProductId(data.item.product_id, data.item.quantity, true)
                "
              >
                <b-icon-pencil-square variant="dark" />
              </b-button>
              <b-button
                size="sm"
                variant="light"
                class="ml-1 shadow-sm border"
                @click="handleDelete(data.item.id)"
              >
                <b-icon-x-circle-fill variant="danger" />
              </b-button>
            </template>
          </b-table>
        </RowCol>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import axios from "axios";
import RowCol from "@/components/RowCol";
import InputText from "@/components/InputText";
import SectionHeader from "@/components/SectionHeader";

export default {
  name: "CobanzadeSidebar",
  components: { SectionHeader, InputText, RowCol },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      note: "",
      fullchecked: false,
      disabled: false,
      items: [],
      fields: [
        { key: "selected", label: "Seç" },
        "N",
        { key: "brand", label: "Brend" },
        { key: "description", label: "Malın Adı" },
        { key: "kod", label: "Kod" },
        { key: "quantity", label: "Say" },
        { key: "price", label: "Qiymət (AZN) " },
        { key: "mebleg", label: "Məbləğ (AZN)" },
        "-",
      ],
    };
  },
  computed: {
    total() {
      if (!this.items) return 0;
      return this.items.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0
      );
    },
    total_quantity() {
      if (!this.items) return 0;
      return this.items.reduce((acc, item) => acc + item.quantity, 0);
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkMobile);
    this.fetchItems();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    async fetchItems() {
      console.log('Fetching Cobanzade basket items...');
      this.disabled = true;
      this.fullchecked = false;
      try {
        const result = await axios.post("/basket/index", { warehouse_id: 2 });
        this.items = result.data.results;
        console.log('Cobanzade basket items:', this.items); 
        // Update store with count
        this.$store.commit('SET_BASKET_COUNT', { 
          warehouse_id: 2, 
          count: this.items.length 
        });
        
        for (let item of this.items) {
          if (item.selected) {
            this.fullchecked = true;
            break;
          }
          this.fullchecked = false;
        }
      } catch (error) {
        console.error('Error fetching Cobanzade basket items:', error);
      } finally {
        this.disabled = false;
      }
    },
    handleFullCheck() {
      this.fullchecked = !this.fullchecked;
      const data = { selected: this.fullchecked, warehouse_id: 2 };
      axios.post("/basket/full/check", data).then((result) => {
        this.$store.commit("SET_GLOBAL_TOAST", result.data);
        this.fetchItems();
      });
    },
    handleFullDelete() {
      if (confirm("ƏMİNSƏN?")) {
        axios.post("/basket/full/delete", { warehouse_id: 2 }).then((result) => {
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchItems();
        });
      }
    },
    handleDelete(id) {
      if (confirm("ƏMİNSƏN?")) {
        const data = { detail_id: id, warehouse_id: 2 };
        axios.post("/basket/one/delete", data).then((result) => {
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchItems();
        });
      }
    },
    handleSelectedChange(id, event) {
      const data = { detail_id: id, selected: event, warehouse_id: 2 };
      axios.post("/basket/toggle_selected_one", data).then((result) => {
        this.$store.commit("SET_GLOBAL_TOAST", result.data);
        this.fetchItems();
      });
    },
    handleSubmitBasket(event) {
      event.preventDefault();
      if (confirm("ƏMİNSƏN?")) {
        const data = { note: this.note, warehouse_id: 2 };
        this.disabled = true;
        axios.post("/basket/approve", data).then((result) => {
          this.disabled = false;
          this.note = "";
          this.$store.commit("SET_GLOBAL_TOAST", result.data);
          this.fetchItems();
        });
      }
    },
    setProductId(product_id, quantity, update) {
      this.$root.$emit('bv::show::modal', 'basket_modal', {
        product_id,
        quantity,
        update,
        warehouse_id: 2
      });
    },
  },
};
</script>

<style scoped>
.b-sidebar {
  z-index: 1050;
}
.mobile-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  background: #fff;
}

.mobile-list .list-item {
  width: 100%;
}

.mobile-list .list-item:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

.mobile-close-btn {
  position: fixed;
  top: 45px;
  right: 15px;
  padding: 8px;
  cursor: pointer;
  z-index: 1060;
  background: white;
}

@media (max-width: 768px) {
  .b-sidebar >>> .b-sidebar-body {
    padding-top: 60px !important;
  }

  /* Table styles */
  .table {
    margin: 0;
    border: none !important;
  }

  .table td, .table th {
    padding: 8px !important;
    font-size: 13px;
    border: 1px solid #dee2e6 !important;
  }

  /* Form styles */
  .b-form.form-inline {
    display: flex;
    flex-direction: column;
    padding: 15px 0;
  }

  .b-form.form-inline .form-group {
    margin-bottom: 10px;
    width: 100%;
  }

  .b-form.form-inline .btn {
    width: 100%;
    margin: 5px 0 !important;
    padding: 8px;
  }
}
.basket-table {
  margin-bottom: 0;
}

:deep(.table) {
  margin-bottom: 0;
}

:deep(.table th),
:deep(.table td) {
  padding: 0.5rem;
  vertical-align: middle;
}

:deep(.table thead th) {
  background-color: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
}
</style>
